<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-10">
      <span class="a-fs-16 a-fw-700">设备类型管理</span>
    </div>
    <el-card class="el-main">
      <le-pagview id="leSearch" :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="name" label="设备名称"></el-table-column>
          <el-table-column prop="name" label="设备类型"></el-table-column>
          <el-table-column prop="pic" label="设备图片">
            <template slot-scope="{ row }">
              <el-image
                v-if="row.pic"
                style="width: 56px; height: 56px"
                :src="row.pic ? row.pic : '../../assets/icon/device-type.png'"
                :preview-src-list="[row.pic ? row.pic : '../../assets/icon/device-type.png']"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="设备价格（元）">
            <template slot-scope="{ row }">
              <span>{{ row.price ? (row.price / 100).toFixed(2) : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="socket" label="设备库存">
            <template slot-scope="{ row }">
              <span>{{ row.socket ? row.socket : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="110" fixed="right">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                <img
                  src="../../assets/icon/edit.png"
                  class="a-wh-16"
                  @click="editDeviceType(row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal">种设备</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "DeviceTypeList",

  data() {
    return {
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getDeviceType,
        method: "post",
        params: {},
        freshCtrl: 1,
      },
    };
  },
  methods: {
    setTableData(data) {
      this.tableData = data;
    },
    editDeviceType(datas) {
      this.$router.push({
        path: "/device/device-type-edit",
        query: {
          id: datas.id,
        },
      });
    },
  },
};
</script>

<style scoped></style>
